import * as React from "react"
import HeardTopWrapper from "../components/header-wrapper"
import Layout from "../components/layout/index"
import PageContainer from "../components/page-container"
import loadable from '@loadable/component'
//import FreeSuccessModal from "../components/freeTrial/freeSuccessModal"
import "../components/freeTrial/style.scss"
import { Helmet } from 'react-helmet';
import {useTranslation} from 'gatsby-plugin-react-i18next'
import{graphql} from "gatsby"
//
//import ReactFormInputValidation from "react-form-input-validation"

//import FreeTrailForm from '../components/freeTrial/freeTrailForm'
//const FreeTrailForm = require ('../components/freeTrial/freeTrailForm'))
const FreeTrailForm = loadable(() => import('../components/freeTrial/form'))
FreeTrailForm.preload()
//const {ReactFormInputValidation} = loadable(() => import("react-form-input-validation"))
//const isBrowser = typeof window !== "undefined"
const FreeTrialPage = () => {
    const {t} = useTranslation();
   // const [reactLoadingVisibility, setReactLoadingVisibility] = useState( true )
    
  return(
    <div className="freeTrial">
     <HeardTopWrapper title={t('freeTrial')} 
      shortDes1={t('freeTrialDes1')}
      shortDes2={t('freeTrialDes2')}
      shortDes3={t('freeTrialDes3')}/>
     <Layout>
      <Helmet>
         <title>{t('myKitchenInspector')}｜{t('startFreeTrial')}</title>
      </Helmet>
      <PageContainer>
        <FreeTrailForm/>
      </PageContainer>
    </Layout>
    </div>
  )
}

export default FreeTrialPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;